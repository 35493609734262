import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div style={{padding: "30px"}}>
        <div className="title-container d-flex align-items-center justify-content-center">
            <h2 className="font-size-80 font-pnb privacy-title">Privacy Notice</h2>
        </div>

        <p className="font-size-20 font-pnr mt-4 line-height-1">
            "CRYPTODATA" or "we," "us," or "our" refers to CRYPTODATA TECH SRL, acting on behalf of its affiliates doing business as
            CRYPTODATA or other brands owned by CRYPTODATA. "You" or "your" refers to the person or entity that is the customer of
            record. Please read the following to learn more about our Privacy Notice (<span className="font-pnb">"this Notice"</span>
            ). This Notice applies to the chat service provided by this application (<span className="font-pnb">"the Service"</span>).
        </p>

        <div className="font-size-20 font-pnb mt-4 line-height-1">
            <h3 className="font-size-24 font-pnb mt-4 line-height-1">
                What personal data do we collect and to which purposes do we use your personal data?
            </h3>
            <p className="font-size-20 font-pnr mt-3 mt-sm-4 line-height-1 color-light-gray">
                While using our Service, we may process certain personally identifiable information that can be used to contact or
                identify you (<span className="font-pnb">"Personal Data"</span>). Personally identifiable information may include, but
                is not limited to:
            </p>
        </div>

        <>
            <p className="font-size-20 font-pnr mt-4 line-height-1">
                <span className="font-pnb font-size-24 ps-lg-4 ps-1">1. Identification Data:</span> e.g. the e-mail address, first name
                and last name.
            </p>
            <p className="font-size-20 font-pnr line-height-1 color-light-gray ps-lg-5 ps-2 mt-3 mt-sm-4">
                We may use your Personal Data to contact you with newsletters, marketing or promotional materials, and other information
                that may be of interest to you, if you have provided consent to this processing or if we can base this processing on a
                legitimate interest that is not overridden by your fundamental rights. You may in the future withdraw that consent or
                object to receiving any, or all, of these communications from us by following the unsubscribe link or instructions
                provided in any email we send or by contacting us.
            </p>
        </>
        <>
            <p className="font-size-20 font-pnr mt-4 line-height-1">
                <span className="font-pnb font-size-24 ps-lg-4 ps-1">2. Account Data</span>
            </p>
            <p className="font-size-20 font-pnr mt-3 mt-sm-4 line-height-1 color-light-gray ps-lg-5 ps-2">
                Some Services may allow or require that you register for a personalized account. Account data may include in addition
                your account name, authentication information, registration date, contact information, and any other information
                associated with your account.
            </p>
        </>
        <>
            <p className="font-size-20 font-pnr mt-4 line-height-1">
                <span className="font-pnb font-size-24 ps-lg-4 ps-1">3. Usage Data</span>
            </p>
            <p className="font-size-20 font-pnr mt-3 mt-sm-4 line-height-1 color-light-gray ps-lg-5 ps-2">
                We may also collect information that your browser sends whenever you visit our Service or when you access the Service,
                incl. by or through a mobile device ("Usage Data").
            </p>
            <p className="font-size-20 font-pnr mt-3 mt-sm-4 line-height-1 color-light-gray ps-lg-5 ps-2">
                This Usage Data may include information such as your computer's Internet Protocol address (e.g. IP address), browser
                type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those
                pages, unique device identifiers and other diagnostic data.
            </p>
            <p className="font-size-20 font-pnr mt-3 mt-sm-4 line-height-1 color-light-gray ps-lg-5 ps-2">
                When you access the Service by or through a mobile device, this Usage Data may include information such as the type of
                mobile device you use, the IP address of your mobile device, your mobile operating system, the app version, the type of
                mobile Internet browser you use, unique device identifiers and other diagnostic data.
            </p>
        </>
        <>
            <p className="font-size-20 font-pnr mt-4 line-height-1">
                <span className="font-pnb font-size-24 ps-lg-4 ps-1">4. Location Data</span>
            </p>
            <p className="font-size-20 font-pnr mt-3 mt-sm-4 line-height-1 color-light-gray ps-lg-5 ps-2">
                We may use and store information about your location if you give us permission to do so{" "}
                <span className="font-pnb">(“Location Data”).</span> We use this data to provide features of our Service (only to allow
                you to share your location to another user via the app, if it was enabled by the administrator).
            </p>
            <p className="font-size-20 font-pnr mt-3 mt-sm-4 line-height-1 color-light-gray ps-lg-5 ps-2">
                You can enable or disable location services when you use our Service at any time, through your device settings.
            </p>
        </>
        <>
            <p className="font-size-20 font-pnr mt-4 line-height-1 ">
                <span className="font-pnb font-size-24 ps-lg-4 ps-1">5. Content you provide</span>
            </p>
            <p className="font-size-20 font-pnr mt-3 mt-sm-4 line-height-1 color-light-gray ps-lg-5 ps-2">
                When you use our Services you may provide content into that service (e.g. upload file, send a message).{" "}
            </p>
        </>
        <>
            <p className="font-size-20 font-pnr mt-4 line-height-1">
                <span className="font-pnb font-size-24 ps-lg-4 ps-1">6. Tracking & Cookies Data</span>
            </p>
            <p className="font-size-20 font-pnr mt-3 mt-sm-4 line-height-1 color-light-gray ps-lg-5 ps-2">
                We use cookies and similar tracking technologies to track the activity on our Service and hold certain information. We
                regularly monitor aggregated activity data on our infrastructure, but it is not tracking of individual users in the
                sense of this paragraph, which only occurs when we have a legitimate interest to do so (e.g. for security and compliance
                purposes).
            </p>
            <p className="font-size-20 font-pnr mt-3 mt-sm-4 line-height-1 color-light-gray ps-lg-5 ps-2">
                Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are sent to your
                browser from a website and stored on your device. Tracking technologies that could also be used are beacons, tags, and
                scripts to collect and track information and to improve and analyze our Service.
            </p>
            <p className="font-size-20 font-pnr mt-3 mt-sm-4 line-height-1 color-light-gray ps-lg-5 ps-2">
                You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not
                accept cookies, you may not be able to use some portions of our Service.
            </p>
        </>
        <>
            <p className="font-size-24 font-pnb mt-4 line-height-1">How do we use information?</p>
            <p className="font-size-20 font-pnr mt-4 line-height-1 color-light-gray">
                We collect and use your personal data to the extent necessary to carry out our operations and provide our services as
                well as to comply with any regulatory obligations in our activities. To this end, these purposes are defined in more
                detail below:
            </p>
            <p className="font-size-20 font-pnr mt-3 mt-sm-4 line-height-1 ps-lg-4 ps-1">1. To provide and maintain our Service</p>
            <p className="font-size-20 font-pnr mt-3 mt-sm-4 line-height-1 ps-lg-4 ps-1">
                2. To notify you about changes to our Service
            </p>
            <p className="font-size-20 font-pnr mt-3 mt-sm-4 line-height-1 ps-lg-4 ps-1">
                3. To allow you to participate in interactive features of our Service when you choose to do so
            </p>
            <p className="font-size-20 font-pnr mt-3 mt-sm-4 line-height-1 ps-lg-4 ps-1">4. To provide customer support</p>
            <p className="font-size-20 font-pnr mt-3 mt-sm-4 line-height-1 ps-lg-4 ps-1">
                5. To gather analysis or valuable information so that we can improve our Service
            </p>
            <p className="font-size-20 font-pnr mt-3 mt-sm-4 line-height-1 ps-lg-4 ps-1">6. To monitor the usage of our Service</p>
            <p className="font-size-20 font-pnr mt-3 mt-sm-4 line-height-1 ps-lg-4 ps-1">
                7. To detect, prevent and address technical issues
            </p>
            <p className="font-size-20 font-pnr mt-3 mt-sm-4 line-height-1 ps-lg-4 ps-1">
                8. To provide you with news, special offers and general information about other goods, services and events which we
                offer that are similar to those that you have already purchased or enquired about if you have provided consent to
                receive this information or the processing is in our legitimate interests and it's not overridden by your rights.
            </p>
        </>
        <>
            <p className="font-size-24 font-pnb mt-4 line-height-1">Lawful Basis for Processing Personal Data</p>
            <p className="font-size-20 font-pnr mt-4 line-height-1 color-light-gray">
                In accordance with the applicable regulations, we may only use your personal data for at least one of the following
                reasons:
            </p>
            <p className="font-size-20 font-pnr mt-3 mt-3 mt-sm-4 line-height-1 color-light-gray">
                <span className="font-pnb white-color ps-lg-4 ps-1">1. To comply with legal and regulatory obligations;</span> we
                collect and use your personal data to comply various legal and regulatory obligations.
            </p>
            <p className="font-size-20 font-pnr mt-3 mt-3 mt-sm-4 line-height-1 color-light-gray">
                <span className="font-pnb white-color ps-lg-4 ps-1">2. To fulfil our legitimate interest;</span> we also use your
                personal data to fulfill our legitimate interests, which include the following: provision and delivery of our products
                and services; customer communication and development of our customer relationships; development of our products and
                services; Security and safety of our IT and facilities.
            </p>
            <p className="font-size-20 font-pnr mt-3 mt-3 mt-sm-4 line-height-1 color-light-gray">
                <span className="font-pnb white-color ps-lg-4 ps-1">3. Based on your consent:</span> if processing of certain personal
                data requires your consent (e.g. unsolicited marketing), we will inform you of this including details of the specific
                processing activity and request your consent to such processing. You may request to revoke your consent at any time.
            </p>

            <p className="font-size-20 font-pnr mt-3 mt-3 mt-sm-4 line-height-1">
                If you do not wish for us to process data for the cases where there is a legal obligation or such data are required to
                provide you the Service, we will be in a situation where we cannot provide you the Service.{" "}
            </p>
        </>

        <>
            <p className="font-size-24 font-pnb mt-4 line-height-1">Retention of Data</p>
            <p className="font-size-20 font-pnr mt-4 line-height-1 color-light-gray mt-3 mt-sm-4">
                We will retain your Personal Data only for as long as the account is registered with us, afterwards we will permanently
                delete the information after a 1-year term. We will further retain and use your Personal Data to the extent necessary to
                comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws),
                resolve disputes, and enforce our legal agreements and policies.
            </p>
            <p className="font-size-20 font-pnr mt-4 line-height-1 color-light-gray mt-3 mt-sm-4">
                Sometimes, we will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter
                period of time, except when this data is used to strengthen the security or to improve the functionality of our Service,
                or we are legally obligated to retain this data for longer time periods.
            </p>
        </>

        <>
            <p className="font-size-24 font-pnb mt-4 line-height-1">Your Rights</p>
            <p className="font-size-20 font-pnr mt-4 line-height-1">
                In accordance with applicable regulations and where applicable, you have the following rights:{" "}
            </p>

            <p className="font-size-20 font-pnr mt-3 mt-sm-4 line-height-1 color-light-gray">
                <span className="font-pnb white-color ps-lg-4 ps-1">1. To access:</span> you can obtain information relating to the
                processing of your personal data, and a copy of such personal data.
            </p>
            <p className="font-size-20 font-pnr mt-3 mt-sm-4 line-height-1 color-light-gray">
                <span className="font-pnb white-color ps-lg-4 ps-1">2. To rectify:</span> where you consider that your personal data are
                inaccurate or incomplete, you can request that such personal data be modified accordingly.
            </p>
            <p className="font-size-20 font-pnr mt-3 mt-sm-4 line-height-1 color-light-gray">
                <span className="font-pnb white-color ps-lg-4 ps-1">3. To erase:</span> you can require the deletion of your personal
                data, to the extent permitted by law.
            </p>
            <p className="font-size-20 font-pnr mt-3 mt-sm-4 line-height-1 color-light-gray">
                {" "}
                <span className="font-pnb white-color ps-lg-4 ps-1">4. To restrict:</span> you can request the restriction of the
                processing of your personal data.{" "}
            </p>
            <p className="font-size-20 font-pnr mt-3 mt-sm-4 line-height-1 color-light-gray">
                <span className="font-pnb white-color ps-lg-4 ps-1">5. To object:</span> you can object to the processing of your
                personal data, on grounds relating to your particular situation. You have the right to object to the processing of your
                personal data for direct marketing purposes, which includes profiling related to such direct marketing.
            </p>
            <p className="font-size-20 font-pnr mt-3 mt-sm-4 line-height-1 color-light-gray">
                <span className="font-pnb white-color ps-lg-4 ps-1">6. To data portability:</span> where legally applicable, you have
                the right to have the personal data you have provided to us be returned to you or, where technically feasible,
                transferred to a third party.
            </p>
            <p className="font-size-20 font-pnr mt-3 mt-sm-4 line-height-1 color-light-gray">
                <span className="font-pnb white-color ps-lg-4 ps-1">7. To withdraw your consent: </span>where you have given your
                consent for the processing of your personal data, you have the right to withdraw your consent at any time.
            </p>
        </>

        <>
            <p className="font-size-24 font-pnb mt-4 line-height-1">How to exercise your rights? </p>
            <div className="font-size-20 font-pnr mt-3 mt-sm-4 line-height-1">
                If you wish to exercise the rights listed above, please send your request to our contact e-mail address{" "}
                <a href="mailto:office@cryptodata.com" className="color-main-blue">
                    office@cryptodata.com
                </a>
                .
            </div>
            <p className="font-size-20 font-pnr mt-3 mt-sm-4 line-height-1">
                In accordance with applicable regulation, in addition to your rights above, you are also entitled to lodge a complaint
                with the competent supervisory authority.
            </p>
        </>

        <>
            <p className="font-size-24 font-pnb mt-4 line-height-1">Other data recipients </p>
            <p className="font-size-20 font-pnr mt-3 mt-sm-4 line-height-1">
                We may employ third party companies and individuals to facilitate our Service ("Service Providers"), to provide the
                Service on our behalf, to perform Service-related services or to assist us in analyzing how our Service is used.{" "}
            </p>
            <div className="font-size-20 font-pnr mt-3 mt-sm-4 line-height-1">
                For example, we use Firebase services provided by Google Inc. We also encourage you to review the Google's policy for
                safeguarding your data. For more information on what type of information Firebase collects, please visit please visit
                the Google Privacy & Terms web page:{" "}
                <a href="https://firebase.google.com/support/privacy" target="_blank" className="color-main-blue">
                    https://firebase.google.com/support/privacy
                </a>
                . According to this document, Google Inc. sometimes transfers data to the USA and uses standard contractual clauses as a
                mechanism to be allowed to transfer such data.
            </div>
        </>

        <>
            <p className="font-size-24 font-pnb mt-4 line-height-1">Changes to This Privacy Notice</p>
            <p className="font-size-20 font-pnr mt-3 mt-sm-4 line-height-1">
                We may update our Privacy Notice from time to time. We will notify you of any changes by posting the new Privacy Notice
                on this page.{" "}
            </p>
            <p className="font-size-20 font-pnr mt-3 mt-sm-4 line-height-1">
                We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and
                update the "effective date" at the top of this Privacy Notice.{" "}
            </p>
            <p className="font-size-20 font-pnr mt-3 mt-sm-4 line-height-1">
                You are advised to review this Privacy Notice periodically for any changes. Changes to this Privacy Notice are effective
                when they are posted on this page.{" "}
            </p>
        </>

        <>
            <p className="font-size-24 font-pnb mt-4 line-height-1">Contact Us</p>
            <p className="font-size-20 font-pnr mt-3 mt-sm-4 line-height-1">
                If you have any questions about this Privacy Policy, please contact us{" "}
                <a href="mailto:office@cryptodata.com" className="color-main-blue">
                    office@cryptodata.com
                </a>
                .
            </p>

            <p className="font-size-20 font-pnb mt-3 mt-sm-4 line-height-1">CryptoDATA TECH SRL</p>
            <p className="font-size-20 font-pnb mt-3 mt-sm-4 line-height-1">
                1/II Pipera Boulevard, Voluntari, Ilfov County, Romania
            </p>
        </>
    </div>
  );
}

export default App;
